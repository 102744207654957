import React from 'react'

// Profile data in JSON format
const teamData = [
    {
        name: 'Khushvant Chahar',
        imgsrc: '/Khushwant.jpeg',
        desc: 'Co-Founder and CEO',
        github: 'https://github.com/KhushvantChahar',
        linkedin: 'https://www.linkedin.com/in/khushvant-chahar/'
    },
    {
        name: 'Shriya Mittal',
        imgsrc: '/Shriya.jpeg',
        desc: 'Co-Founder and CFO',
        linkedin: 'https://www.linkedin.com/in/shriya-mittal-0445931b5/'
    },
    {
        name: 'Uddesh Teke',
        imgsrc: '/Uddesh.jpeg',
        desc: 'Co-Founder and CTO',
        github: 'https://github.com/oneandhalffeet',
        linkedin: 'https://www.linkedin.com/in/uddesh-teke/'
    }
];

const Team = () => {
    return (
        <div className='flex flex-col items-center px-4 md:px-6 lg:px-8'>
            <h1 className='font-mono text-3xl md:text-4xl lg:text-5xl font-bold p-2 md:p-3 text-center'>Meet the team</h1>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10 w-full place-items-center py-6 card-grid-flex'>
                {teamData.map((profile, index) => (
                    <ProfileCard 
                        key={index}
                        name={profile.name}
                        imgsrc={profile.imgsrc}
                        desc={profile.desc}
                        github={profile.github}
                        linkedin={profile.linkedin}
                    />
                ))}
            </div>
        </div>
    )
}

const ProfileCard = ({ name, imgsrc, desc, github, linkedin }) => {
    return (
        <div className='flex flex-col items-center p-4 rounded-lg shadow-lg w-full max-w-xs hover:shadow-2xl transition-shadow'>
            <img 
                src={imgsrc} 
                alt={name} 
                className='w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 object-cover mb-3 rounded-full'
            />
            <h3 className='text-lg md:text-xl font-medium text-center font-mono font-extrabold'>{name}</h3>
            <p className='text-center'>{desc}</p>
            {(github || linkedin) && (
                <div className='flex justify-center gap-6 p-2'>
                    {github && (
                        <a href={github} target="_blank" rel="noopener noreferrer" className="hover:opacity-75 transition-opacity">
                            <svg className='size-6' role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <title>GitHub</title>
                                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
                            </svg>
                        </a>
                    )}
                    {linkedin && (
                        <a href={linkedin} target="_blank" rel="noopener noreferrer" className="hover:opacity-75 transition-opacity">
                            <svg className='size-6' role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <title>LinkedIn</title>
                                <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                            </svg>
                        </a>
                    )}
                </div>
            )}
        </div>
    )
}

const AboutUs = ({ t }) => {
    return (
        <div className='container mx-auto'>
            <div className='p-4 md:p-6 lg:p-10 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 lg:gap-16'>
                {/* Mission Section */}
                <div className='text-center bg-cyan-200 hover:bg-cyan-250 order-2 md:order-1 rounded-md border-2 border-grey-500 p-4 drop-shadow-md hover:drop-shadow-2xl'>
                    <h1 className='font-mono text-3xl md:text-4xl lg:text-5xl font-extrabold p-2 mb-4'>Our Mission</h1>
                    <p className='text-base md:text-lg lg:text-xl'>{t.missionDescription}</p>
                </div>
                
                {/* Mission Image */}
                <div className='flex justify-center items-center order-1 md:order-2'>
                    <img
                        src="/Logo.png"
                        alt="NextDoor Dukan Logo"
                        className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 object-contain"
                    />
                </div>
                
                {/* Values Image */}
                <div className='flex justify-center items-center order-3'>
                    <img
                        src="/Logo.png"
                        alt="NextDoor Dukan Logo"
                        className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 object-contain"
                    />
                </div>
                
                {/* Values Section */}
                <div className='text-center bg-cyan-200 hover:bg-cyan-250 order-4 rounded-md border-2 border-grey-700 p-4 drop-shadow-md hover:drop-shadow-2xl'>
                    <h1 className='font-mono text-3xl md:text-4xl lg:text-5xl font-bold p-2 mb-4'>Our Values</h1>
                    <p className='text-base md:text-lg lg:text-xl'>{t.visionDescription}</p>
                </div>
            </div>
            
            {/* Team Section */}
            <Team />
        </div>
    )
}

export default AboutUs;