import React from 'react';
import { Plus, Minus } from 'lucide-react';
import { useCart } from './CartContext';

// Import all images
const PRODUCTS = [
    { 
        id: 'p1', 
        name: 'Fresh Vegetables', 
        image: require('./assets/images/fresh-veggies.jpg'), 
        price: 149 
    },
    { 
        id: 'p2', 
        name: 'Fresh Fruits', 
        image: require('./assets/images/fresh-fruits.jpg'), 
        price: 199 
    },
    { 
        id: 'p3', 
        name: 'Baby Care', 
        image: require('./assets/images/baby-care.png'), 
        price: 499 
    },
    { 
        id: 'p4', 
        name: 'Bakery Items', 
        image: require('./assets/images/bakery.jpg'), 
        price: 299 
    },
    { 
        id: 'p5', 
        name: 'Bath & Body', 
        image: require('./assets/images/bath-body.png'), 
        price: 399 
    },
    { 
        id: 'p6', 
        name: 'Beauty Store', 
        image: require('./assets/images/beauty-store.jpg'), 
        price: 599 
    },
    { 
        id: 'p7', 
        name: 'Biscuits & Cakes', 
        image: require('./assets/images/biscuit-cakes.png'), 
        price: 199 
    },
    { 
        id: 'p8', 
        name: 'Breakfast Cereals', 
        image: require('./assets/images/cereals.png'), 
        price: 249 
    },
    { 
        id: 'p9', 
        name: 'Chocolates & Sweets', 
        image: require('./assets/images/choclates-sweets.png'), 
        price: 299 
    },
    { 
        id: 'p10', 
        name: 'Cleaning Essentials', 
        image: require('./assets/images/cleaning.png'), 
        price: 349 
    },
    { 
        id: 'p11', 
        name: 'Cold Drinks & Juices', 
        image: require('./assets/images/colddrink-juice.png'), 
        price: 99 
    },
    { 
        id: 'p12', 
        name: 'Dairy & Bread', 
        image: require('./assets/images/dairy-bread.png'), 
        price: 149 
    },
    { 
        id: 'p13', 
        name: 'Home Kitchen', 
        image: require('./assets/images/home-kitchen.jpg'), 
        price: 799 
    },
    { 
        id: 'p14', 
        name: 'Ice Cream', 
        image: require('./assets/images/ice-cream.jpg'), 
        price: 249 
    },
    { 
        id: 'p15', 
        name: 'Jewellery', 
        image: require('./assets/images/jewellery.jpg'), 
        price: 999 
    },
    { 
        id: 'p16', 
        name: 'Masala & Dry Fruits', 
        image: require('./assets/images/masala-dryfruit.png'), 
        price: 599 
    },
    { 
        id: 'p17', 
        name: 'Noodles & Pasta', 
        image: require('./assets/images/noodle-pasta.png'), 
        price: 199 
    },
    { 
        id: 'p18', 
        name: 'Cooking Oils & Ghee', 
        image: require('./assets/images/oil-ghee.png'), 
        price: 699 
    },
    { 
        id: 'p19', 
        name: 'Sauces & Spreads', 
        image: require('./assets/images/sauces-spreads.png'), 
        price: 249 
    },
    { 
        id: 'p20', 
        name: 'Snacks & Namkeen', 
        image: require('./assets/images/snacks-namkeen.png'), 
        price: 149 
    },
    { 
        id: 'p21', 
        name: 'Tea & Coffee', 
        image: require('./assets/images/tea-coffee.png'), 
        price: 299 
    }
];

const Product = ({ id, name, image, price }) => {
    const { state, dispatch } = useCart();
    const cartItem = state.items.find(item => item.id === id);
    const quantity = cartItem?.quantity || 0;

    const addToCart = () => {
        dispatch({
            type: 'ADD_TO_CART',
            payload: { id, name, image, price }
        });
    };

    const updateQuantity = (newQuantity) => {
        if (newQuantity === 0) {
            dispatch({
                type: 'REMOVE_FROM_CART',
                payload: id
            });
        } else {
            dispatch({
                type: 'UPDATE_QUANTITY',
                payload: { id, quantity: newQuantity }
            });
        }
    };

    return (
        <div className='p-4 flex flex-col items-center space-y-2'>
            <img
                src={image}
                alt={name}
                className="w-20 h-20 md:w-24 md:h-24 lg:w-32 lg:h-32 object-cover rounded-lg"
            />
            <h2 className='font-bold text-center text-sm md:text-base'>{name}</h2>
            <p className="text-orange-500">₹{price}</p>
            
            {quantity === 0 ? (
                <button 
                    onClick={addToCart}
                    className='border-orange-500 rounded-md py-2 px-4 border-2 text-orange-500 hover:bg-orange-50 transition-colors'
                >
                    Add to Cart
                </button>
            ) : (
                <div className='flex items-center space-x-3 border-2 border-orange-500 rounded-md p-1'>
                    <button 
                        onClick={() => updateQuantity(quantity - 1)}
                        className='p-1 hover:bg-orange-50 rounded-md'
                    >
                        <Minus className="w-5 h-5 text-orange-500" />
                    </button>
                    <span className='text-orange-500 w-8 text-center'>{quantity}</span>
                    <button 
                        onClick={() => updateQuantity(quantity + 1)}
                        className='p-1 hover:bg-orange-50 rounded-md'
                    >
                        <Plus className="w-5 h-5 text-orange-500" />
                    </button>
                </div>
            )}
        </div>
    );
};

const Products = () => {
    return (
        <div className="flex justify-center w-full py-4">
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-4 place-items-center place-content-center w-11/12 md:w-10/12 lg:w-9/12">
                {PRODUCTS.map((product) => (
                    <Product 
                        key={product.id}
                        {...product}
                    />
                ))}
            </div>
        </div>
    );
};

export default Products;