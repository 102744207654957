import React from 'react';
import { FaLinkedin, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center text-[#4A1010]">Contact Us</h2>
      
      <div className="space-y-6">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <FaLinkedin size={24} className="text-gray-700" />
          </div>
          <a 
            href="https://www.linkedin.com/company/nextdoor-dukan/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-gray-700 hover:text-blue-600 transition-colors"
          >
            Nextdoor दुकान
          </a>
        </div>

        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <FaPhoneAlt size={24} className="text-gray-700" />
          </div>
          <span className="text-gray-700">
            +91 8079069160
          </span>
        </div>

        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <FaEnvelope size={24} className="text-gray-700" />
          </div>
          <a 
            href="mailto:support@nextdoordukan.com"
            className="text-gray-700 hover:text-blue-600 transition-colors"
          >
            support@nextdoordukan.com
          </a>
        </div>

        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <FaMapMarkerAlt size={24} className="text-gray-700" />
          </div>
          <span className="text-gray-700">
            Ground Floor, D-9, Sector 3, Gautam Buddha Nagar, Uttar Pradesh, 201301
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;