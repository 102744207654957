import React from 'react'

const Logo = () => (
    <div className="flex items-center gap-x-2">
        <img
            src="/Logo.png"
            alt="NextDoor Dukan Logo"
            className="w-14 h-14"
        />
        <div>
            <h1 className="text-xl text-[#501F21] font-carter-one">
                NextDoor{' '}
                <span className="font-eczar font-semibold">
                    दुकान
                </span>
            </h1>
            <p className="text-sm text-[#2F4F4F] font-carter-one">
                Empowering Local Kirana Stores
            </p>
        </div>
    </div>
);

export default Logo;