import React, { useState } from 'react';


const PageLayout = ({ children }) => (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-[1920px] px-4">
        {children}
      </div>
    </div>
  );

const Careers = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cv: null
  });

  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState('');

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (!formData.cv) {
      newErrors.cv = 'CV is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(file.type)) {
        setErrors(prev => ({
          ...prev,
          cv: 'Please upload a PDF or Word document'
        }));
        return;
      }
      // Check file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        setErrors(prev => ({
          ...prev,
          cv: 'File size should be less than 5MB'
        }));
        return;
      }
      setFormData(prev => ({
        ...prev,
        cv: file
      }));
      setFileName(file.name);
      setErrors(prev => ({
        ...prev,
        cv: ''
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Here you would typically send the data to your server
      console.log('Form submitted:', formData);
      // Reset form after successful submission
      setFormData({ name: '', email: '', cv: null });
      setFileName('');
      alert('Application submitted successfully!');
    }
  };

  return (
    <PageLayout>
      <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-[#4A1010]">Careers</h2>
        <p className="mb-6 text-gray-600 text-center">Join our team and be part of something special</p>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#6d3636] ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="Enter your name"
            />
            {errors.name && <p className="mt-1 text-sm text-red-500">{errors.name}</p>}
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#6d3636] ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="Enter your email"
            />
            {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
          </div>

          <div>
            <label htmlFor="cv" className="block text-sm font-medium text-gray-700 mb-1">
              Upload CV
            </label>
            <div className="mt-1 flex items-center">
              <label className="w-full flex justify-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 cursor-pointer focus-within:outline-none focus-within:ring-2 focus-within:ring-[#6d3636]">
                <span>{fileName || 'Choose file'}</span>
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                  className="sr-only"
                />
              </label>
            </div>
            {errors.cv && <p className="mt-1 text-sm text-red-500">{errors.cv}</p>}
            <p className="mt-1 text-xs text-gray-500">PDF or Word documents only (max. 5MB)</p>
          </div>

          <button
            type="submit"
            className="w-full bg-[#6d3636] text-white py-2 px-4 rounded-md hover:bg-[#4A1010] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-[#6d3636] focus:ring-offset-2"
          >
            Submit Application
          </button>
        </form>
      </div>
    </PageLayout>
  );
};

export default Careers;