import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Trash2 } from 'lucide-react';
import { FaShoppingCart } from "react-icons/fa";
import Logo from './Logo';
import { useCart } from './CartContext';

const pages = [
  { name: 'Home', path: '/' },
  { name: 'About Us', path: '/about' },
  { name: 'Join Us', path: '/join' },
  { name: 'Contact Us', path: '/contact' },
  { name: 'Careers', path: '/careers' }
];

const CartSidebar = ({ isOpen, onClose }) => {
  const { state, dispatch } = useCart();
  const totalAmount = state.items.reduce((total, item) => total + (item.price * item.quantity), 0);

  const removeItem = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: id });
  };

  const updateQuantity = (id, quantity) => {
    if (quantity === 0) {
      removeItem(id);
    } else {
      dispatch({
        type: 'UPDATE_QUANTITY',
        payload: { id, quantity }
      });
    }
  };

  return (
    <div 
      className={`fixed inset-y-0 right-0 z-50 w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Shopping Cart ({state.items.length})</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {state.items.map((item) => (
            <div key={item.id} className="flex items-center space-x-4 border-b pb-4">
              <img src={item.image} alt={item.name} className="w-20 h-20 object-cover rounded" />
              <div className="flex-1">
                <h3 className="font-medium">{item.name}</h3>
                <p className="text-orange-500">₹{item.price}</p>
                <div className="flex items-center space-x-2 mt-2">
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    className="px-2 py-1 border rounded"
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    className="px-2 py-1 border rounded"
                  >
                    +
                  </button>
                  <button 
                    onClick={() => removeItem(item.id)}
                    className="ml-4 p-1 text-red-500 hover:bg-red-50 rounded"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="border-t p-4 space-y-4">
          <div className="flex justify-between items-center">
            <span className="font-semibold">Total:</span>
            <span className="font-semibold text-orange-500">₹{totalAmount}</span>
          </div>
          <button 
            className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition-colors"
            onClick={() => {/* Implement checkout */}}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

function ResponsiveAppBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { cartCount } = useCart();

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    } else {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const CartIcon = () => (
    <div className="relative">
      <button 
        onClick={() => setIsCartOpen(true)}
        className="focus:outline-none"
      >
        <FaShoppingCart size={30} className="text-[#9E1A1A] hover:text-[#7a1414] transition-colors" />
        {cartCount > 0 && (
          <div className="absolute -top-2 -right-2 bg-orange-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
            {cartCount}
          </div>
        )}
      </button>
    </div>
  );

  return (
    <>
      <div className="w-full flex justify-center">
        <nav className="relative w-full max-w-[1920px]">
          <div className="hidden md:flex justify-between items-center w-full px-8">
            <Logo />
            <div className="bg-[#9E1A1A] rounded-[40px] w-[978px] h-[60px] flex justify-evenly items-center">
              {pages.map((page) => (
                <Link
                  key={page.name}
                  to={page.path}
                  className="text-white hover:bg-white/10 px-4 py-2 rounded-lg transition-colors duration-200 font-roboto text-base"
                >
                  {page.name}
                </Link>
              ))}
            </div>
            <CartIcon />
          </div>

          {/* Mobile Navigation */}
          <div className="md:hidden">
            <div className="flex justify-between items-center px-4">
              <Logo />
              <CartIcon />
            </div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="fixed top-4 right-4 z-50 bg-[#9E1A1A] p-2 rounded-lg transition-colors duration-200 hover:bg-red-800"
              aria-label={isOpen ? 'Close menu' : 'Open menu'}
            >
              {isOpen ? (
                <X className="h-6 w-6 text-white" />
              ) : (
                <Menu className="h-6 w-6 text-white" />
              )}
            </button>

            {(isOpen || isAnimating) && (
              <div 
                className={`fixed inset-y-0 right-0 z-40 bg-[#9E1A1A] flex items-center justify-center w-64
                  transform transition-transform duration-300 ease-in-out
                  ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
              >
                <div className="flex flex-col items-center space-y-8 pt-16">
                  {pages.map((page, index) => (
                    <Link
                      key={page.name}
                      to={page.path}
                      onClick={() => setIsOpen(false)}
                      className={`text-white text-xl hover:bg-white/10 px-6 py-3 rounded-lg 
                        transition-all duration-300 transform
                        ${isOpen ? 'translate-x-0 opacity-100' : 'translate-x-8 opacity-0'}`}
                      style={{
                        transitionDelay: `${index * 100}ms`
                      }}
                    >
                      {page.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>

      {/* Cart Sidebar */}
      <CartSidebar 
        isOpen={isCartOpen} 
        onClose={() => setIsCartOpen(false)} 
      />
    </>
  );
}

export default ResponsiveAppBar;